<template>

  <div class="follow_item" v-if="props.data && is_show">
    
    
    <div class="event">
      <div class="label">
        <NuxtLink :class="'user_link ' + (data.is_premium ? 'is_premium ' : '') + (data.is_premier ? 'is_premier' : '')" :to="data.link">
           <img :alt="data.name" :class="(data.s_type == 'diary' ? 'report_avatar ' : 'user_avatar')" :src="data.avatar_small">
           <div :class="'user_range u_range_' + data.status"></div>
        </NuxtLink>
      </div>
      <div class="content">
        <div class="summary">
          <NuxtLink :to="data.link">
            {{ data.name }}
          </NuxtLink>
          <div class="date">
            {{ $constants.status[data.status] && $constants.status[data.status] != '-' ? $t($constants.status[data.status]) : '' }}
          </div>

          <div v-if="useAuth().isAuth() && useAuth().getId() != data.author_id" class="btns_follow">
        
            <UiButton
              v-if="!follows_list.includes( data.s_type + ':' + data.id)"
              @click="follow(data.s_type,data.id)"
              :name="$t('universal_button_follow')"                     
              />
             
            <UiButton
              v-if="follows_list.includes( data.s_type + ':' + data.id)"
              @click="unfollow(data.s_type,data.id)"
              :name="$t('universal_button_unfollow')"                     
              />
             
          </div>

        </div>


      </div>

    </div>

  </div>

</template>

<script setup>
 
 const { $api, $popup } = useNuxtApp();

 const props = defineProps({
   data: {
     type: Object
   },
   follows: {
     type: Array,
      default: () => []
   },
 });

 const is_show = ref(true);
 const follows_list = ref(props.follows);

  const follow = function(type, id){
      $api.postFollow(type, id)
      .then((res) => {
        follows_list.value.push(type + ':' + id);
      })
      .catch(function (error) {
        $popup.error('Error');
      });
  }

  const unfollow = function(type, id){
      $api.deleteFollow(type, id)
      .then((res) => {
        follows_list.value.splice(follows_list.value.indexOf(type + ':' + id), 1);
      })
      .catch(function (error) {
        $popup.error('Error');
      });
  }


 
</script>

<style scoped>
 
  
/* FOLLOW */

.follow_item{
  display: flex;
    align-items: flex-start;
    margin-bottom: 20px;
        width: 100%;
}

.follow_item .content{
    margin: 0.5em 0em 0.35em 1.14em;
    display: block;
    width: 100%;
}

.follow_item .content .summary{
    margin: 0em;
    font-size: 1em;
    font-weight: bold;
    color: var(--un-text-color-gray);
}

.follow_item .content .summary .date{
    display: inline-block;
    float: none;
    font-weight: normal;
    font-size: 0.85714286em;
    font-style: normal;
    margin: 0em 0em 0em 0.5em;
    padding: 0em;
    color: var(--un-text-color-gray);
}

.follow_item .btns_follow,
.follow_item .btns_follow{
    display: inline-block;
    float: right;
}

.follow_item .event .user_link,
.follow_item .event .user_link{
    position: relative;
    color: var(--un-text-color);
    text-decoration: none;
    height: 60px;
}
.follow_item .event .user_range,
.follow_item .event .user_range{
    position: absolute;
    bottom: -10px;
    right: -10px;
}

.follow_item .event .report_avatar{
    border-radius: 3px!important;
}

.follow_item .event{
    position: relative;
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 45px;
    padding: 0.21rem 0em;
    margin: 0em;
    background: none;
    border-top: none;
}

.follow_item .event .label {
    display: block;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 2.5em;
    -webkit-align-self: stretch;
    -ms-flex-item-align: stretch;
    align-self: stretch;
    text-align: left;
    height: 60px;
}

.follow_item .event .user_avatar{
    width: 40px;
    border-radius: 30px;
    vertical-align: middle;
    margin-right: 5px;
}
.follow_item .event .user_name{
    display: inline-block;
    vertical-align: middle;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: calc(100% - 56px);
}

.follow_item .event .follow_btn{
    box-shadow: 0px 0px 0px 1px #198f35 inset !important;
    color: #198f35 !important;
    background: transparent none !important;
    font-weight: normal;
    height: 1em;
}

.follow_item .event .unfollow_btn{
    box-shadow: 0px 0px 0px 1px #db2828 inset !important;
    color: #DB2828 !important;
    background: transparent none !important;
    font-weight: normal;
    height: 1em;
}

@media (max-width: 768px) {

    .follow_item{

    }
    .follow_item .btns_follow,
    .follow_item .btns_follow{
        position: absolute;
        right: 0px;
        top: 0px;
    }
    .follow_item > .event > .content a{
        max-width: calc(100% - 111px);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: inline-block;
        vertical-align: middle;
    }
}
  



</style>
